<template>
  <div class="container-fuild">
    <div class="row justify-content-center m-0 p-lg-5 p-3">
      
      <div
        class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 login-card shadow-lg p-4 rounded "
      >
        <div class="text-center">
          <span class="text-center">
            <i class="bi bi-kanban-fill fs-1 icon"></i>
          </span>
          <br />
          <span class="fs-3">
            Confirmation !
          </span>
        </div>
        <br />
        <p class="lh-2 fs-5 text-center">
          Un code de confirmation à six chiffres a été envoyé à votre adresse e-mail 
        </p>
        <div class="text-center">
          <button @click="resendCode(auth)" class="btn m-auto btn-link text-center">
            Renvoyé le code de confirmation
          </button>
        </div>
        <br>
        <form >
          

          <div>
            <label class="form-label"> Code de confirmation  </label>
            <div></div>
            <input 
              v-model="auth.confirmation_code"
              v-on:keyup.enter="validateConfirmationCode(auth)"
              :type="'text'"

              class="form-control opactity-8"
              :class="passwordError ? 'border text-danger border-danger' : ''"
            />
            <div class="text-danger">
              {{ passwordError }}
            </div>
          </div>

          <br />
           
        </form>

        <br />
        <div class="col">
          <button type="submit" @click="validateConfirmationCode(auth)" class="btn btn-primary w-100 py-2 px-4">
            <span v-if="!progress"> Connecter </span>
            <span v-else-if="progress"> Vérification en cours </span>
          </button>
          <div class="text-center text-danger p-2">
            {{ error }}
          </div>
        </div>
      </div>
    </div> 

    <div
      class="row d-xl-none d-none justify-content-center align-items-center pt-5 px-3 h-75"
    >
      <div class="col-11 bg-white rounded text-center text-danger py-5 px-3">
        <div class="text-center">
          <i
            class="bi bi-exclamation-diamond text-warning fs-1"
            :style="{ fontSize: '80px !important' }"
          ></i>
        </div>
        <hr />
        <div class="lh-4 fs-5">
          L'application ne fonctionne pas sur les téléphones car elle est conçue
          pour les ordinateurs. Veuillez vous connecter à partir de votre
          ordinateur pour accéder à l'application.
        </div>
        <hr />
        <div class="">
          <a
            href="https://api.whatsapp.com/send?phone=212600302731"
            class="nav-link d-flex align-items-center btn btn-success p-2 py-3"
          >
            <i
              class="bi bi-whatsapp text-white"
              :style="{ fontSize: '38px !important' }"
            ></i>
            <span class="text-white">
              Cliquez ici pour en savoir plus sur WhatsApp
            </span>
          </a>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      auth: {},
      progress: false,
      showPassword: false
    };
  },
  methods: {
    async validateConfirmationCode(auth) {
      await (this.progress = !this.progress);
      await (auth.username = this.$route.query.username);
      await this.$store.dispatch("auth/validateConfirmationCode", auth);
      setTimeout((this.progress = !this.progress), 6000);
    },

    async resendCode(auth) {
      await (this.progress = !this.progress);
      await (auth.username = this.$route.query.username);
      await this.$store.dispatch("auth/resendConfirmationCode", auth);
      setTimeout((this.progress = !this.progress), 6000);
    },
  },
  computed: {
    ...mapGetters("auth", {
      usernameError: "getUsernaneError",
      passwordError: "getPasswordError",
      error: "getError",
    }),
  },
  created() {
    try {
      if (this.$route.query.username && this.$route.query.password) {
        const username = this.$route.query.username;
        const password = this.$route.query.password;
        alert({ username, password });

        // Assuming your validateConfirmationCode method expects an object
        this.validateConfirmationCode({ username, password }); // Pass username and password as data
      }
    } catch (e) {
      console.log(e.message);
    }
  },
};
</script>

<style scoped>
.container-fuild {
  min-height: 100vh;
  /* background-color: red; */
  /*background-color: #689ae6;*/
   /* background-image: -webkit-linear-gradient(
    -28deg,
    #d9725f 0%,
    #d9725f 60%,
    #6f73fc 60%,
    #6f73fc 60%
  ); */
  /*background-image: linear-gradient(
    -28deg,
    #d9725f 0%,
    #d9725f 60%,
    #6f73fc 60%,
    #6f73fc 60%
  ); */
  background-image: url('../../assets/banner.svg') !important;
  background-size: contain !important;
}

.login-card {
  background: rgba(255, 255, 255, 0.6);

  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}
.opactity-8{
  background: rgba(255, 255, 255, 0.9);
}
@keyframes icon-animation {
  0% {
    color: #689ae6;
  }
  50% {
    color: #b14343;
  }
  100% {
    color: #689ae6;
  }
}
.icon {
  animation-name: icon-animation;
}
.fs-1 {
  font-size: 55px !important;
}
.form-control {
  padding: 0.6rem 0.8rem;
}
 .text-shadow-example , .text-shadow-example span >*  {
      font-size: 5px;
      font-weight: bold;
      text-shadow: 2px 2px 4px rgba(145, 145, 145, 0.5);
    }
</style>
